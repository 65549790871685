import React from "react";
import PageLayout from "../layouts/PageLayout";
import Seo from "../components/Seo/Seo"
import TermsSection from "../sections/TermsSection";
import { graphql } from "gatsby";

const TermsPage = ({ data }) => {

    if (!data) return null

    const pageContent = data.prismicTermspage
    const page = pageContent.data || {}

    const metaTitle = page.meta_title
    const metaDescription = page.meta_description
    const metaKeywords = page.meta_keywords

    return (

        <PageLayout>
            <Seo
                title={metaTitle}
                description={metaDescription}
                keywords={metaKeywords}
            />
            <TermsSection title={page.title} content={page.content} slices={page.body}/>
        </PageLayout>
    )
}

export default TermsPage

export const query = graphql`
    query TermsPageQuery($id: String) {
        prismicTermspage(id: {eq: $id}) {
            id
            uid
            url
            lang
            data {
              title
              meta_title
              meta_keywords
              meta_description
              index_page
              flag_page
              content
              body {
                ... on PrismicTermspageDataBodyTermsContent {
                  id
                  slice_type
                  slice_label
                  primary {
                    title
                  }
                  items {
                    content
                  }
                }
              }
            }
          }
    }
`